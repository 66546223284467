import Fetcher    from "Scripts/common/fetcher";
import Form       from "Scripts/common/form";

export default class CustomCodes {
	constructor() {
		this.modal  = $("#modal-custom-codes");

		this.getList();
	}

	getList() {
		console.log("CustomCodes.getList()");

		this.list = new Fetcher({
			element:  "#js-custom-codes-fetcher",
			callback: this.bindButtons.bind(this),
			force:    true,
		});
	}

	bindButtons() {
		$(".js-custom-code-trigger").on("click", this.showModal.bind(this));
	}

	showModal(e) {
		console.log("CustomCodes.showModal()", $(e.currentTarget).data('amount'));
		new Fetcher({
			callback: this.setupForm.bind(this),
			data:     $(e.currentTarget).data(),
			element:  "#js-custom-codes-modal-fetcher",
			modal:    this.modal,
			trigger:  $(e.currentTarget)
		});
	}

	setupForm() {
		console.log("CustomCodes.setupForm()");
		new Form({
			callback: this.postReviewModal.bind(this),
			form:     $("#custom-codes-form"),
		});
	}

	postReviewModal() {
		this.modal.modal("hide");
		this.list.reset();
	}
}