/**
 * Close page modal JS. This allows users to close their their page from a modal, which could live on any page.
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/fundraising/modals/page-update/page-update.vm):
 * - class="js-page-close-modal-trigger"
 * - data-id="the-page-id"
 *
 * E.g. <button class="js-page-update-modal" data-id="1234">Post an update</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Daniel Moffat
 */
'use strict';

const LoadingButton = require('Scripts/common/loading-button');

module.exports = CampaignClose;

function CampaignClose() {
	// The ID of the page being opened.
	this.id = null;

	this.init = function() {
		this.ui = {
			modal: $('#js-modal-page-close'),
			modalBody: $('.js-modal-page-close-body')
		};
		if(!this.ui.modal.length) {
			console.warn('No page close modal found on page, is it included? (page-close.vm). Not doing anything.');
			return;
		}

		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-page-close-modal-trigger', this.onClickModalTrigger.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('click', '.js-close-page', this.onClickCloseBtn.bind(this));
	};

	this.onClickModalTrigger = function(e) {
		e.preventDefault() // Enable it to be used on <a> elements.
		var clickedEl = $(e.currentTarget);
		
		this.id = clickedEl.data('id');
		console.log("Closing page " + this.id);
		
		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}
		var triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		this.load().always(function() {
			triggerBtn.enable();
		});
	};

	this.load = function() {
		var self = this;
		return $.ajax({
			url: '/charity/campaigns/close-page-modal?id=' + this.id
		}).then(function(resp) { // resp is raw HTML.
			self.ui.modalBody.html(resp);
			self.ui.modal.modal('show');
		});
	};

	this.onClickCloseBtn = function() {
		console.log('Clicked close button.');
		var closeBtn = new LoadingButton($('.js-close-page'));
		closeBtn.disable();
		
		this.close().then(function(resp) {
			if(resp.success) {
				console.log('Page closed!');
				// Reload the current page to trigger the success message.
				window.location.reload();
			}
		}).always(function() {
			closeBtn.enable();
		});
	};

	this.close = function() {
		return $.ajax({
			url: '/charity/campaigns/close',
			data: { id: this.id },
			method: 'POST'
		});
	};

	this.init();
}