import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import fetchJson            from 'Scripts/common/fetch-json';
import DragNDrop            from 'Scripts/common/drag-n-drop';
import Fetcher              from "Scripts/common/fetcher";
import Form                 from "Scripts/common/form";
import LoadingButton        from 'Scripts/common/loading-button';
import showErrorBar         from 'Scripts/common/show-error-bar';

export default class TicketAgendaForm {
	constructor() {
		this.list = new Fetcher({
			callback: this.onLoadList.bind(this),
			element:  "#js-agenda-fetcher",
		});
	}

	onLoadList() {
		console.log("TicketAgendaForm.onLoadList");

		$(".js-add-agenda").on("click", this.onClickAddBtn.bind(this));
		$(".js-edit-agenda").on("click", this.onClickEditBtn.bind(this));
		$(".js-remove-agenda").on("click", this.onClickRemoveBtn.bind(this));

		new DragNDrop({
			callback: this.onReorder.bind(this),
		});
	}

	onClickAddBtn(e) {
		console.log("TicketAgendaForm.onClickAddBtn");

		// Clear add modal, avoids issues with duplicate IDs
		this.editModal?.empty();

		this.addModal = new Fetcher({
			element: $("#js-add-agenda-modal-fetcher"),
			modal: $("#js-modal-add-agenda"),
			trigger: e.currentTarget,
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickEditBtn(e) {
		console.log("TicketAgendaForm.onClickEditBtn");

		const btn = $(e.currentTarget);

		// Clear add modal, avoids issues with duplicate IDs
		this.addModal?.empty();

		this.editModal = new Fetcher({
			element: $("#js-edit-agenda-modal-fetcher"),
			modal: $("#js-modal-edit-agenda"),
			trigger: btn,
			data: btn.data(),
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickRemoveBtn(e) {
		console.log("TicketAgendaForm.onClickRemoveBtn");

		const endpoint = $(e.currentTarget).data('endpoint');
		const modal = $("#js-modal-remove-agenda");
		const btn = modal.find(".js-submit");;

		modal.modal("show");

		btn.off().on("click", () => {
			this.onConfirmRemove(endpoint, btn, modal)
		});
	}

	async onConfirmRemove(endpoint, btn, modal) {
		console.log("TicketAgendaForm.onConfirmRemove");

		btn = new LoadingButton(btn);
		btn.disable();

		const { success } = await fetchJson(endpoint, { method: 'POST' });

		if(!success) {
			showErrorBar();
		}

		btn.enable();
		modal.modal('hide');
		this.list.fetch();
	}

	onLoadModal(fetcher) {
		console.log("TicketAgendaForm.onLoadModal");

		const modal = fetcher.options.modal;
		const form = modal.find("form");

		this.form = new Form({
			form: form,
			callback: () => {
				console.log("TicketAgendaForm.onLoadModal.callback")
				modal.modal('hide');
				this.list.fetch();
			},
		});
	}

	onReorder(reorderedItem) {
		console.log("TicketAgendaForm.onReorder", reorderedItem);
		const endpoint = reorderedItem.data('endpoint') + "/" + (reorderedItem.index() + 1);
		fetchJson(endpoint, { method: 'POST' });
	}
}