import AjaxFormErrorHandler   from 'Scripts/common/ajax-form-error-handler';
import ImageUploadCrop        from 'Scripts/common/image-upload-crop';
import ImageUploadGallery     from 'Scripts/common/image-upload-gallery';
import LoadingButton          from 'Scripts/common/loading-button';
import Slugify                from 'Vendor/slugify';
import TextAreaExpand         from 'Scripts/common/text-area-expand';
import UriAvailabilityChecker from 'Scripts/donate/uri-availability-checker';

export default class CampaignForm {
	constructor() {
		this.ui = {
			// Forms
			form:         $('#js-create-campaign-form'),
			advForm:      $('#js-adv-campaign-form'),
			corpForm:     $('#js-corporate-list-form'),
			corpAddForm:  $('#js-corporate-add-form'),
			// Modals
			corpModal:    $('#modal-campaign-corporate'),
			// Inputs
			inputTarget:  $('#target'),
			inputTitle:   $('#title'),
			inputUri:     $('#uri'),
			inputCtas:    $('.js-cta'),
			inputTwitter: $('#shareTextTwitter'),
			// Toggles and buttons
			generateUri:  $('.js-generate-uri'),
			toggleTarget: $('.js-toggle-target'),
			handle:       $('.js-handle'),
			addCorpBtn:   $('.js-add-corp'),
			// Other
			corpList:     $("#js-corp-list"),
			corpNone:     $(".js-corp-none"),
		};

		// The banner
		this.image = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
		});

		// The banner gallery 
		this.gallery = new ImageUploadGallery({
			cropper: this.image,
			preview: $(".js-crop-result"),
			previewContainer: $(".js-crop-result-container"),
		});

		// The corporate logos
		this.corpLogo = new ImageUploadCrop({
			width:     200,
			height:    150,
			restrict:  false,
			container: this.ui.corpModal,
			modal:     $('#js-modal-image-crop-corporate'),
			callback:  this.addCorpLogo.bind(this),
		});

		// Empty array for managing the logos
		this.corpLogos = [];

		// Errors
		this.errorHandler = new AjaxFormErrorHandler({ useClasses: true });

		// Button
		this.ui.button = new LoadingButton(this.ui.form.find('.js-submit'));

		// URI checker
		new UriAvailabilityChecker('.js-uri-lookup', '/charity/campaigns/uri-availability');

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();
	
		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.generateUri.on('click', this.generateUri.bind(this, true));
		this.ui.inputCtas.on('change', this.onChangeCtas.bind(this));
		this.ui.inputTitle.on('blur', this.generateUri.bind(this, false));
		this.ui.toggleTarget.on('change', this.onToggleTarget.bind(this));
		this.ui.handle.on('click', this.onClickHandle.bind(this));

		this.ui.corpList.on('click', '.js-corp-move', this.moveCorp.bind(this));
		this.ui.corpList.on('click', '.js-corp-edit', this.editCorp.bind(this));
		this.ui.corpList.on('click', '.js-corp-delete', this.deleteCorp.bind(this));
		this.ui.corpAddForm.on('submit', this.addCorp.bind(this));

		this.ui.form.on('submit', this.onSubmit.bind(this));
	}

	// Allow user to disable the fundraising target. Removes any existing value but remembers it so you can toggle
	onToggleTarget() {
		let disabled = this.ui.toggleTarget.is(":checked");
		let input = this.ui.inputTarget; 

		input.prop('disabled', disabled);

		if(disabled){
			input.data('value',input.val()).val('');
		} else {
			input.val(input.data('value') || '');
		}
	}

	// Generate a URI for the page, based on slug of title. Will auto-trigger validation to see if it exists already or not.
	generateUri(force, e) {
		let generatedUri = Slugify(this.ui.inputTitle.val(), {lower: true, remove: /[^A-Za-z0-9-_\s]/g}); // Spaces are "allowed" as Slugify auto-changes to dashes
		console.log("CampaignForm.generateUri()", generatedUri, force);

		let inputUri = this.ui.inputUri;
		let currentUri = inputUri.val();

		if(!$('.js-uri-lookup-result').hasClass('text-green') || force){
			inputUri.val(generatedUri).trigger('keyup');
		}
		else {
			this.ui.generateUri.collapse(currentUri.startsWith(generatedUri) ? 'hide' : 'show');
		}
	}

	// Don't allow them to set primary CTA == secondary CTA (todo: deal with in Java as well, just in case)
	onChangeCtas(e) {
		console.log("CampaignForm.onChangeCtas()");

		let changed  = $(e.currentTarget);
		let other    = this.ui.inputCtas.filter((i,e) => { return e != changed[0] });

		if(changed.val() == other.val()) {
			other.find("option").each((i,option) => {
				if($(option).val() != '' && $(option).val() != changed.val()) {
					other.val($(option).val());
				}
				return;
			})
		}
	}

	onClickHandle(e) {
		console.log("CampaignForm.onClickHandle()");
		let handle = $(e.currentTarget).text();
		this.ui.inputTwitter.append(' ' + handle);
	}

	// Before submitting, get the form data from the advanced modal, then add image. todo: is there a neater way to do this?
	getFormData() {
		console.log("CampaignForm.getFormData()");

		let formData     = new FormData(this.ui.form.get(0));
		let advFormData  = new FormData(this.ui.advForm.get(0));
		let corpFormData = new FormData(this.ui.corpForm.get(0));

		for (let pair of advFormData.entries()) {
			formData.append(pair[0], pair[1]);
		}

		// Add corporate stuff. The logo bit is a bit hacky, the input has the index of the array of saved blobs
		for (let pair of corpFormData.entries()) {
			let [ key, value ] = pair;

			if(key.endsWith(".logo")) {
				value = this.corpLogos[value] || null;
			}
			
			if(value != null) {
				formData.append(key, value);	
			}
		}

		if(this.gallery.lastChosenImage == "gallery"){
			return formData;
		}

		return this.image.addImagesToFormData(formData, "images");
	}

	// Click the "add corporate" button. Sorry, this is a bit fiddly/specific, todo: make more flexible?
	addCorp(e) {
		console.log("CampaignForm.addCorp()");

		e.preventDefault();

		let inputId      = $(".js-corp-id");
		let inputName    = $(".js-corp-name");
		let inputWebsite = $(".js-corp-website");
		let inputImage   = this.corpLogo.ui.cropResult;

		let editId       = inputId.val();
		let newName      = inputName.val();
		let newWebsite   = inputWebsite.val();
		let newLogo      = inputImage.attr("src");
		let error        = $(".js-error-corp");
		let editing      = !!editId;

		if(newName.trim() == "" || newWebsite.trim() == "" || (!editing && (newLogo == "" || inputImage.is(":hidden")))) {
			error.collapse('show');
			return;
		}

		let newRow;
		
		if(editing) {
			newRow = $($(".js-corp-single")[editId]);
		}
		else {
			newRow = $($("#js-corp-template")[0].content.cloneNode(true));
		}

		let inputs       = newRow.find("input");
		let rowName      = newRow.find("b");
		let rowWebsite   = newRow.find("b+a");
		let rowImage     = newRow.find("img");

		rowName.text(newName);
		rowWebsite.attr('href',newWebsite);
		rowWebsite.text(newWebsite);
		rowImage.attr("src", newLogo);

		$(inputs[0]).val(newName);
		$(inputs[1]).val(newWebsite);

		let logoId = $(".js-corp-logo-id").val();
		if(logoId != "") {
			$(inputs[2]).val(logoId);
		}

		inputId.val("");
		inputName.val("");
		inputWebsite.val("");

		this.corpLogo.ui.cropResultContainer.collapse('hide');
		error.collapse('hide');
		
		this.changeCorpButton('add');

		if(!editing) {
			newRow.appendTo(this.ui.corpList);
			this.resetCorpOrder();
		}
	}

	editCorp(e) {
		console.log("CampaignForm.editCorp");

		let row = $(e.currentTarget).parents(".js-corp-single");
		console.log(row);

		let inputId      = $(".js-corp-id");
		let inputName    = $(".js-corp-name");
		let inputWebsite = $(".js-corp-website");

		inputId.val(row.index());
		inputName.val(row.find("b").text());
		inputWebsite.val(row.find("b+a").text());

		this.corpLogo.ui.cropResult.attr("src", row.find("img").attr("src"));
		this.corpLogo.ui.cropResultContainer.collapse("show");

		this.changeCorpButton('edit');
	}

	changeCorpButton(type) {
		this.ui.addCorpBtn.find("span").text(this.ui.addCorpBtn.data(type));
	}

	// After the corporate logo is cropped
	addCorpLogo() {
		console.log("CampaignForm.addCorpLogo()");
		let modal = this.ui.corpModal;
		let event = 'shown.bs.modal';

		modal.modal('show');

		modal.on(event, () => {
			modal.animate({scrollTop: this.ui.corpAddForm.offset().top - 200}, 200);	
			modal.off(event);
		});

		this.corpLogos.push(this.corpLogo.croppedImage);

		// Save the index of the logo in the array to the input, this is used later to fetch the blob
		$(".js-corp-logo-id").val(this.corpLogos.length - 1);
	}

	// Change order of corporates - we don't have an order input as unnecessary, that field is set in the Java
	moveCorp(e) {
		console.log("CampaignForm.moveCorp()");

		let el       = $(e.currentTarget);
		let dir      = el.data('dir');
		let parent   = el.parents(".js-corp-single");
		let newPos   = parent.index() + (dir == "up" ? 0 : 1);
		let selector = "#js-corp-list > :nth-child(" + newPos + ")";

		if(dir == "up" && newPos > 0) {
			parent.detach().insertBefore(selector);
		}
		else if(dir == "down" && newPos < this.ui.corpList.children().length){
			parent.detach().insertAfter(selector);
		}

		this.resetCorpOrder();
	}

	// Need to make sure numbers are sequential otherwise Java validation fails
	resetCorpOrder() {
		console.log("CampaignForm.resetCorpOrder()");

		let corps = this.ui.corpList.find(".js-corp-single");

		corps.each((i,corp) => {
			$(corp).find("input").each((j,input) => {
				$(input).attr("name", $(input).attr("name").replaceAll(/[0-9]/g, i));
			});		
		});

		this.ui.corpNone.collapse(corps.length ? 'hide' : 'show');
	}

	deleteCorp(e) {
		console.log("CampaignForm.deleteCorp()");
		$(e.currentTarget).parents(".js-corp-single").remove();
		this.resetCorpOrder();
	}

	onSubmit(e) {
		e.preventDefault();
		console.log('CampaignForm.submit()');
		
		let endpoint = this.ui.form.attr('action');
		let formData = this.getFormData();

		this.ui.button.disable();

		return $.ajax({
			url: endpoint,
			method: 'POST',
			data: formData,
			processData: false,
			contentType: false
		}).then(resp => {
			if(resp.success) {
				this.ui.button.success();
				window.location.href = '/charity/campaigns';
			} else {
				this.ui.button.enable();
				this.errorHandler.handleErrors(resp.payload.validation);
			}
		});
	}
}