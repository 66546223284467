import Fetcher from "Scripts/common/fetcher";
import Form    from "Scripts/common/form";

export default class CharityEvent {
	constructor() {
		this.list = new Fetcher({
			element:  "#js-events-fetcher"
		});

		this.modal = $("#event-modal");

		$("#js-event-create").on('click', this.showModal.bind(this));
	}

	// Display modal where donation reasons can be created/edited
	showModal(e) {
		console.log("CharityEvent.showModal()");

		new Fetcher({
			callback: this.setupEditForm.bind(this),
			element:  "#js-event-modal-fetcher",
			modal:    this.modal,
			trigger:  $(e.currentTarget)
		});
	}

	setupEditForm() {
		console.log("CharityEvent.setupEditForm()");

		new Form({
			callback: this.submitModal.bind(this),
			form:     $("#event-form"),
		});
	}

	submitModal() {
		console.log("CharityEvent.submitModal");

		this.modal.modal("hide");
		this.list.reset();
	}
}