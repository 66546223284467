export default class InputUrl {
	constructor(selector) {
		const elements = $(selector);

		elements.each((i, element) => {
			element = $(element);

			element.on("click", e => {
				const prefix = element.data('prefix') || '';
				const input = $(element.data('target'));

				if(input.length && input.val().length) {
					const url = prefix + input.val();
					window.open(url, '_blank');
				}
			});
		})
	}
}