import LoadingButton from 'Scripts/common/loading-button';

export default class TicketingClose {
	constructor() {
		// The ID of the page being closed
		this.id = null;

		this.ui = {
			modal: $('#js-modal-page-close'),
			modalBody: $('.js-modal-page-close-body')
		}

		if(!this.ui.modal.length) {
			console.warn('No page close modal found on page, is it included? (page-close.vm). Not doing anything.');
			return;
		}

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-page-close-modal-trigger', this.onClickModalTrigger.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('click', '.js-close-page', this.onClickCloseBtn.bind(this));
	}

	async onClickModalTrigger(e) {
		e.preventDefault() // Enable it to be used on <a> elements.
		const clickedEl = $(e.currentTarget);
		
		this.id = clickedEl.data('id');
		console.log("Closing page " + this.id);

		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}

		const triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		const response = await fetch('/charity/ticketing/close-page-modal?id=' + this.id);

		triggerBtn.enable();

		if(!response.ok) {
			throw new Error("Not 2xx response");
			return
		}

		const data = await response.text();

		this.ui.modalBody.html(data);
		this.ui.modal.modal('show');
	}

	async onClickCloseBtn() {
		console.log('Clicked close button.');
		const closeBtn = new LoadingButton($('.js-close-page'));
		closeBtn.disable();

		const formData = new FormData();
		formData.append("id", this.id);

		const response = await fetch('/charity/ticketing/close', {
			body: formData,
			method: 'POST',
		});

		closeBtn.enable();

		if(!response.ok) {
			throw new Error("Not 2xx response");
			return
		}

		const data = await response.json();

		if(data.success) {
			console.log('Page closed!');
			window.location.reload();
		}
	}
}