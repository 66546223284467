// Javascript for charity logo change page
'use strict';

import AjaxFormErrorHandler from "Scripts/common/ajax-form-error-handler";
import ImageUploadCrop      from 'Scripts/common/image-upload-crop';
import LoadingButton        from 'Scripts/common/loading-button';

export default class CharityLogo {
	constructor() {
		this.image = new ImageUploadCrop({
			width:     320,
			height:    240,
			flexible:  true, // Allows images that are a bit smaller than the dimensions
			restrict:  false,
			callback:  this.ready.bind(this),
		});

		this.errorHandler = new AjaxFormErrorHandler();
		this.form = $("#js-logo-form");
		this.form.on("submit", this.onSubmit.bind(this));
		this.submitContainer = $(".js-submit-container");
	}

	ready() {
		console.log("CharityBanner.ready()");
		this.submitContainer.collapse("show");
	}

	onSubmit(e) {
		console.log("CharityBanner.onSubmit()");
		e.preventDefault();

		let button   = new LoadingButton(this.form.find(".js-submit"));
		let formData = new FormData(e.currentTarget);

		button.disable();

		fetch("/charity/logo", {
			body: this.image.addImagesToFormData(formData),
			method: 'POST',
		})
		.then(response => response.json())
		.then(data => {
			console.log("CharityBanner.onSubmit.data", data);
			
			if(!this.errorHandler.handleErrors(data)) {
				let preview = $("#js-preview, .charity-logo");
				let success = $("#js-success");

				preview.attr('src', data.url);
				success.collapse('show');
				this.image.reset();
				this.submitContainer.collapse("hide");

				$('html, body').animate({scrollTop: success.offset().top - 30}, 400);

	  			button.enable();
			}
		});
	}
}