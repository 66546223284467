// Modal or form page where the user can create or edit charity contacts.
// Used on 
// 1. Logged in charity contact management page
// 2. CRM charity contacts management page
// 3. CRM organisation contacts management page
// 4. CRM dedicated contact page
// @author Matthew Norris

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');
const Select2              = require('Scripts/common/select2');
const Utilities            = require('Scripts/admin/utilities');

module.exports = function EditContact(parent) {

	this.init = function(parent) {
		self.parent = parent;
		self.utilities = new Utilities();
		self.errorHandler = new AjaxFormErrorHandler();

		// Resolve the endpoint. 
		// 	1. "/internal/contact/<contactId>/details" = a dedicated contact page, with a contact form on it
		// 	2. "../contacts/edit" - contact editing modal, found in various places
		self.endpoint = self.utilities.getCrmContactId() 
			? "" // 1
			: self.parent.getEndpoint("contacts/edit"); // 2
		
		console.log("contactId: "+self.utilities.getCrmContactId());
		console.log("endpoint: "+self.endpoint);

		self.modal = $("#contacts-edit-modal");
	};

	// Shows the modal, includes loading the form HTML using AJAX
	this.showModal = function(contactId) {
		console.log("EditContact.showModal()", contactId);

		var modalSpinner = self.modal.find(".modal-body-spinner");
		var modalContent = self.modal.find(".modal-ajax");

		modalContent.empty();
		modalSpinner.show();
		self.modal.modal('show');

		$.ajax(self.endpoint, {
			method: "GET", 
			data: (contactId) ? {"contactId": contactId} : {}
		}).done(function(response) {
			modalSpinner.hide();
			modalContent.html(response);
			self.bindForm();
		});

		return false;	
	};
	
	// Bind submit event handler to the edit contact form
	this.bindForm = function() {
		new Select2(".js-select2");

		$("#contacts-edit-form").off().on("submit", self.postForm);

		this.admin            = $("#adminAccess");
		this.primary          = $("#isPrimary");
		this.isPrimary        = this.primary.is(":checked");
		this.primaryContainer = $("#js-primary");
		
		this.admin.off().on('change', this.toggleAdmin.bind(this));
	};

	// Can only make them primary if an admin
	this.toggleAdmin = function(e) {
		console.log("EditContact.toggleAdmin()");

		var isAdmin   = this.admin.is(":checked");

		this.primary.prop("checked", isAdmin ? this.isPrimary : false);
		this.primaryContainer.collapse(isAdmin ? "show" : "hide");
	}
	
	this.postForm = function() {
		console.log("EditContact.postForm()");

		self.btn = new LoadingButton($("#contacts-edit-form").find(".js-submit"));
		self.btn.disable();
		
		$.ajax(self.endpoint, {
			method: "POST", 
			data: $("#contacts-edit-form").serialize()
		})
		.done(self.receiveResponse);
		
		return false;
	};

	this.receiveResponse = function(response) {
		// Do we need to log the user out (i.e. they de-admined themselves?)
		for (var i = 0; i < response.errors.length; i++) {
			var error = response.errors[i];
			if (error.fieldName == "logout") {
				document.location = "/charity"
				return;
			}
		}

		if (self.errorHandler.handleErrors(response)) {
			// Form validation error
			$("#contacts-edit-modal-oops").hide();
			$("#contacts-edit-modal-oops").slideDown(self.fadeInMs);

			self.btn.enable();
		} 
		else {
			// Successful edit
			$("#contacts-edit-modal").modal("hide");
			if (self.parent) {
				self.parent.list.fetch();
			}
		}
	};

	var self = this;
	self.init(parent);
};