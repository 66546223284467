import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import fetchJson            from 'Scripts/common/fetch-json';
import DragNDrop            from 'Scripts/common/drag-n-drop';
import Fetcher              from "Scripts/common/fetcher";
import Form                 from "Scripts/common/form";
import LoadingButton        from 'Scripts/common/loading-button';
import showErrorBar         from 'Scripts/common/show-error-bar';

export default class TicketTypesForm {
	constructor() {
		this.list = new Fetcher({
			callback: this.onLoadList.bind(this),
			element:  "#js-ticket-list-fetcher",
		});
	}

	onLoadList() {
		console.log("TicketTypesForm.onLoadList");

		$(".js-add-ticket-type").on("click", this.onClickAddBtn.bind(this));
		$(".js-edit-ticket-type").on("click", this.onClickEditBtn.bind(this));
		$(".js-remove-ticket-type").on("click", this.onClickRemoveBtn.bind(this));

		new DragNDrop({
			callback: this.onReorder.bind(this),
		});
	}

	onClickAddBtn(e) {
		console.log("TicketTypesForm.onClickAddBtn");

		this.addModal = new Fetcher({
			element: $("#js-add-ticket-modal-fetcher"),
			modal: $("#js-modal-add-ticket"),
			trigger: e.currentTarget,
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickEditBtn(e) {
		console.log("TicketTypesForm.onClickEditBtn");

		const btn = $(e.currentTarget);

		this.editModal = new Fetcher({
			element: $("#js-edit-ticket-modal-fetcher"),
			modal: $("#js-modal-edit-ticket"),
			trigger: btn,
			data: btn.data(),
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickRemoveBtn(e) {
		console.log("TicketTypesForm.onClickRemoveBtn");

		const endpoint = $(e.currentTarget).data('endpoint');
		const modal = $("#js-modal-remove-ticket");
		const btn = modal.find(".js-submit");;

		modal.modal("show");

		btn.off().on("click", () => {
			this.onConfirmRemove(endpoint, btn, modal)
		});
	}

	async onConfirmRemove(endpoint, btn, modal) {
		console.log("TicketTypesForm.onConfirmRemove");

		btn = new LoadingButton(btn);
		btn.disable();

		const { success } = await fetchJson(endpoint, { method: 'POST' });

		if(!success) {
			showErrorBar();
		}

		btn.enable();
		modal.modal('hide');
		this.list.fetch();
	}

	onLoadModal(fetcher) {
		console.log("TicketTypesForm.onLoadModal");

		const modal = fetcher.options.modal;
		const form = modal.find("form");

		this.form = new Form({
			form: form,
			callback: () => {
				console.log("TicketTypesForm.onLoadModal.callback")
				modal.modal('hide');
				this.list.fetch();
			},
		});

		this.ui = {
			price: $("#price"),
			freeToggle: $(".js-toggle-free"),
		}

		this.ui.price.on("keyup change", this.onUpdatePrice.bind(this));
		this.ui.freeToggle.on("change", this.onToggleFree.bind(this));
	}

	onToggleFree(e) {
		console.log("TicketTypesForm.onToggleFree", $(e.currentTarget).is(":checked"));
		const isChecked = $(e.currentTarget).is(":checked");
		if(isChecked) {
			this.ui.price.attr('data-original', this.ui.price.val())
			this.ui.price.val(0);
		} else {
			this.ui.price.val(this.ui.price.attr('data-original') || null);
		}
	}
	
	onUpdatePrice(e) {
		console.log("TicketTypesForm.onUpdatePrice", $(e.currentTarget).val());
		this.ui.freeToggle.prop("checked", $(e.currentTarget).val() == "0"); // Quoted so that blanks are false not true
	}

	onReorder(reorderedItem) {
		console.log("TicketTypesForm.onReorder", reorderedItem);
		const endpoint = reorderedItem.data('endpoint') + "/" + (reorderedItem.index() + 1);
		fetchJson(endpoint, { method: 'POST' });
	}
}