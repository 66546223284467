// Javascript for compliance agreement
// @author Matthew Norris

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');

module.exports = function ComplianceAgreement() {
	this.init = function() {
		self.errorHandler = new AjaxFormErrorHandler();

		self.form = $("#form-compliance-agreement");
		self.form.on("submit", self.post);
		self.bindTradingChange();

		self.saveBtn = new LoadingButton($("#form-submit"));
	}

	this.bindTradingChange = function() {
		var container     = $("#trading-details-container");
		var cpaTrading    = $("#cpa-trading");
		var cpaNonTrading = $("#cpa-nontrading");

		$('.js-cpa-q').change(function() {
			var el = $(this);
			$("#agreed").prop("checked", false);
			
			if(el.is(":checked")){
				if(el.val() == "1") {
					container.html($("#trading-details-html").html());
					container.collapse('show');
					cpaTrading.show();
					cpaNonTrading.hide();
				}
				else {
					container.collapse('hide');
					cpaTrading.hide();
					cpaNonTrading.show();
				}
			}
		});
	}

	this.post = function() {
		self.saveBtn.disable();
		
		var data = self.form.serialize();
		
		$.ajax("/finance/cpa", {
			method: "POST", 
			data: data
		}).done(self.receiveResponse);
		
		return false;
	}

	this.receiveResponse = function(response) {
		if (self.errorHandler.handleErrors(response)) {
			console.log(response);
			window.scrollTo(0, 0);
			self.saveBtn.enable();

			$("#oops").hide();
			$("#oops").slideDown(self.fadeInMs);
		} 
		else {
			document.location = "/dashboard"; // send user back to home page
		}
	}

	var self = this;
	self.init();
};