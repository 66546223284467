// Wraps around Jquery File Upload plugin to produce a generic, reusable uploader for Everyclick forms
// This is used for HMRC exemption form uploads, case study uploads, and new External Admin, can be 
// used in other places as well.
// @author Matthew Norris

'use strict';

// Import vendor stuff
require('Vendor/blueimp-file-upload'); // Needed for EcFileUploader

module.exports = EcFileUploader;

function EcFileUploader(opts) {
	this.maxSize = 10000000; // 10mb in bytes (well actually a bit less than that) 10mb is pretty standard
	this.filename = null; // Store the filename in the object so it can be reused in other method calls
	
	this.init = function(opts) {
		self.opts = opts;

		let idPrefix = self.opts["elementIdPrefix"];

		self.ui = {
			errors:          $("#"+idPrefix+"-errors"),
			errorsContainer: $("#"+idPrefix+"-errors-container"),
			fileInfo:        $("#"+idPrefix+"_upload-fileinfo"),
			fileInfoName:    $("#"+idPrefix+"_upload-fileinfo-name"),
			icon:            $("#"+idPrefix+"_upload-icon"),
			inProgress:      $("#"+idPrefix+"_upload-inprogress"),
			uploader:        $("#"+idPrefix+"_uploader"),
			uploadId:        $("#"+idPrefix+"_upload-id"),
		}

		// Sets up the actual jQuery File Upload plugin with some options and event handler methods
		self.ui.uploader.fileupload({

			// Function called before POSTing the upload using ajax. It does some pre-validation like checking
			// file type and also file size
			add: function (e, data) {
				// Bug catcher - if you use drag-and-drop and have multiple inputs (e.g. admin>bank-details) then all inputs are triggered for some reason
				if(e.delegatedEvent.type == "drop" && e.target != e.delegatedEvent.target) {
					return;
				}

				console.log("EcFileUploader.add()", $(e.target));
				
				let file = data.originalFiles[0];
				self.filename = file.name;

				let uploadErrors = []
				
				if(file["type"].length && !opts["fileTypesRegex"].test(file["type"])) {
					uploadErrors.push("Please upload the right type of file.");
				}
				
				if (file["size"] > self.maxSize) {
					uploadErrors.push("The uploaded file must be smaller than "+parseInt(self.maxSize / 1000000)+" MB.");
				}

				let errorsContainer = self.ui.errorsContainer;
				let errorsElement   = self.ui.errors;

				errorsElement.html("");
				if(uploadErrors.length > 0) {
					// we have errors
					for (var i = 0; i < uploadErrors.length; i++) {
						errorsElement.append("<p>"+uploadErrors[i]+"</p>");
					}
					errorsContainer.show();

				} else {
					// no errors. clear error box and submit.
					errorsContainer.hide();

					self.ui.fileInfo.hide();
					self.ui.inProgress.show();
					self.ui.icon.hide();

					data.submit(); // triggers the upload process
				}
			},

			// Called when file upload completes - update various form elements to reflect the upload
			done: function (e, data) {
				console.log("EcFileUploader.done()", $(e.target));

				let uploadId = JSON.parse(data.result).id;

				// Hide the progress indicator
				self.ui.inProgress.hide();
				self.ui.icon.show();

				// Update filename indicator and show it
				self.ui.fileInfoName.html(self.filename)
				self.ui.fileInfo.show();

				// Store the ID on the form
				self.ui.uploadId.val(uploadId);

				// Pass the uploaded ID and the file extension to the callback
				if (self.opts.onDone) {	
					self.opts.onDone(uploadId, self.filename.split(".").pop());
				}
			},

			// Backend error, somehow not caught by the front end beforehand
			fail: function(e, data) {
				console.log("EcFileUploader.fail()", $(e.target));

				// Hide the progress indicator
				self.ui.inProgress.hide();
				self.ui.icon.show();

				// Show generic error
				self.ui.errorsContainer.show();
				self.ui.errors.html("<p>File upload failed - did you choose the right type of file?</p>");
			}
		});

		// Toggle class on drag/drop
		self.ui.uploader.on('drag dragstart dragend dragover dragenter dragleave drop', e => {
			$(e.currentTarget).parent(".dragdrop-file").addClass("dragdrop-file-hover");
		})
		.on('dragleave dragend drop', e => {
			$(e.currentTarget).parent(".dragdrop-file").removeClass('dragdrop-file-hover');
		});
	}
	
	var self = this;
	self.init(opts);
};