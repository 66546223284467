// Javascript for resetting user's password
// User enters their email address and hits submit to get confirm link where they can change their password
// @author Matthew Norris

module.exports = function ForgotPassword() {
	this.init = function() {
		self.form = $("#form-forgot-password");
		self.form.on("submit", self.post);
		
		$("#forgot-password-email").on("change keyup paste click", self.textChange);
	}

	this.textChange = function() {
		var val = $("#forgot-password-email").val();
		
		if (val.length > 0) {
			$("#forgot-password-submit").removeClass("disabled");	
		} 
		else {
			$("#forgot-password-submit").addClass("disabled");	
		}
	}

	this.post = function() {
		$("#forgot-password-submit").val("Processing...").addClass("disabled");
		
		$.ajax("/account/reset-password", {
			method: "POST",
			data: self.form.serialize()
		}).done(self.receiveResponse);
		
		return false;
	}

	this.receiveResponse = function(response) {
		$("#forgot-password-submit").val("Submit").removeClass("disabled");
		$("#reset-password-message").hide().slideDown(window.EA.slideMs);
	}

	var self = this;
	self.init();
};
