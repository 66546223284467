// Global stuff that runs on all EA pages
// @author Matthew Norris

const Clipboard = require('Vendor/clipboard');

module.exports = function EaGlobal() {

	this.init = function() {
		this.setupClipboard();
		if (typeof(checkStripe) !== "undefined" && checkStripe) {
			this.checkStripe();
		}
	}

	// We use ClipboardJS to copy links
	this.setupClipboard = function() {
		if(!Clipboard.isSupported()) { return; }
		
		var copied = new Clipboard('.js-copy');

		copied.on('success', function(e) {
			console.log("Copied: " + e.text);

			e.clearSelection();
			var successTarget = $(e.trigger).data('clipboardSuccess');

			if(successTarget){
				$(successTarget).collapse('show');

				if($(e.trigger).data('clipboardHide')){
					setTimeout(function() {
						$(successTarget).collapse("hide");
					}, 3000);
				}
			}
		});
	}

	// AJAX request to check Stripe activation on Stripe's side. If it's found, our
	// database is flagged appropriately and the page refreshed.
	this.checkStripe = function() {
		console.log("Checking Stripe");
		$.ajax("/finance/stripe-setup/check-stripe", {
			method: "POST",
			dataType: "json"
		}).done(function(response) {
			if (response.active) {
				console.log("Stripe is now activated");
				location.reload()
			} else {
				console.log("Stripe is not activated on Stripe's side");
			}
		});
	}

	this.grey = function(text) {
		return '<span class="text-grey-50">' + text + '</span>';
	}

	// Global variables
	window.EA = {
		// How long the slide animation takes - used for alert boxes on various forms
		slideMs: 300,

		// Global variables for reports
		reports: {
			// this is what goes into cells where the user's details have been GDPRed out
			anonHtml: this.grey('Anonymous'),

			// very occasionally, teh date field is missing, this is what replaces
			missingHtml: this.grey('No information'),

			// more blank placeholder
			notProvidedHtml: this.grey('Not provided'),
			notFromCpHtml: this.grey('No campaign page'),
			notFromFpHtml: this.grey('No fundraising page'),
			notFromTpHtml: this.grey('No ticketing page'),
			notFromCorpHtml: this.grey('No corporate'),
			notKnown: this.grey('Not known'),

			// Used for corporate free plans
			basicPlan: this.grey('Not available in Basic Plan'),

			// Date format used for everything
			dateFormat: "YYYY-MM-DD"
		}
	};

	var self = this;
	self.init();
}