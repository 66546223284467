// Contains JS for various WWU related things.

'use strict';

const EcFileUploader = require('Scripts/common/ec-file-uploader'); // needed for charity registration form
const Select2        = require('Scripts/common/select2.js');
const TextAreaExpand = require('Scripts/common/text-area-expand');

module.exports = Register;

function Register() {

	var self = this;

	this.init = function() {
		// Bind charity type menu change event handler
		$('.js-charity-type-select').on('change', function() {
			self.selectType($(this));
		});

		new EcFileUploader({
			elementIdPrefix: "hmrc-certificate",
			fileTypesRegex: /(\.|\/)(gif|jpe?g|png|pdf|doc|docx)$/i
		});

		new EcFileUploader({
			elementIdPrefix: "charity-certificate",
			fileTypesRegex: /(\.|\/)(gif|jpe?g|png|pdf|doc|docx)$/i
		});

		// Disables the main form action when said form is submitted, this is to prevent people clicking multiple times / hammering the enter key
		$('.js-disable-submit').on('submit', function () {
			$(this).find('.js-form-action').attr('disabled', 'disabled');
		});

		$('.js-charity-commission-number').on('blur', this.checkRegNo.bind(this));

		new Select2(".js-select2");

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();
	}

	// When the menu changes, update the hints for the user
	this.selectType = function(element) {
		var typeId = element.val();
		var ccNo   = $('.js-charity-commission-number');
		
		// Update tip text
		$('.js-charity-type-tip').hide();
		$('.js-charity-type-tip-' + typeId).show();

		// Set value for CASC
		if(typeId == 'casc') {
			ccNo.val('CASC');
		}
		else if(ccNo.val() == 'CASC') {
			ccNo.val('');
		}

		// If hmrc-exempt, the user must upload certificate
		$("#hmrc-certificate_container").collapse(typeId == "hmrc-exempt" ? "show" : "hide");
		$("#charity-certificate_container").collapse(typeId == "other" ? "show" : "hide");
	}

	// On the registration form, check their charity reg number, to make 
	this.checkRegNo = function(e) {
		var regNo = $(e.currentTarget).val();
		var regAlert = $("#js-reg-match");
	
		console.log("Register.checkRegNo: " + regNo);

		if(regNo.length < 5) {
			regAlert.collapse('hide');
			return;
		}

		$.ajax({
			url: '/register/check',
			data: { q: regNo }
		}).then(function(resp) {
			console.log(resp);
			var payload = resp.payload;

			if(payload.results > 0) {
				regAlert.find('a').attr('href', '/register/admin?cid=' + payload.cid);
				regAlert.find('span').text(payload.name);
				regAlert.collapse('show');
			}
		});
	}

	self.init();
};