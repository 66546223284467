/*
	JS handling creation and editing of bank account details
	This is used in 2 places
		1. Where charities submit new bank details
		2. Where EC admins amend existing charity bank details
	@author Matthew Norris
*/

import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import EcFileUploader       from 'Scripts/common/ec-file-uploader';
import Fetcher              from "Scripts/common/fetcher";
import LoadingButton        from 'Scripts/common/loading-button';

export default class BankDetailsEdit {
	constructor(config) {
		this.type = null;
		this.modal = $("#bank-submit-modal");
		this.config = config;
		// Regex defining a suitable format for bank verification document upload
		// This is the bit after the "/" in the MIME type. For Word docs it can be quite
		// a few different things.
		this.docRegex = /(\.|\/)(gif|jpe?g|png|pdf|doc|ms-doc|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/i
	}

	// Initialise bank details modal
	startNewModal(target) {
		new Fetcher({
			callback: this.setupForm.bind(this),
			data:     target.data(),
			element:  "#js-edit-bank-modal-fetcher",
			modal:    this.modal,
		});
	}

	setupForm() {
		this.errorHandler = new AjaxFormErrorHandler();

		// Show 'other' text box for bank name
		$("#bankNameSelect").off().change(function() {
			var showOther = $(this).val() == "Other" ? 'show' : 'hide';
			var otherField = $(".js-other-bank");
			otherField.collapse(showOther);
			if(showOther){
				otherField.find('input').focus();
			}
		});

		// Format sortcode
		var sortcode = $(".js-bank-sort-code-input");
		sortcode.off().on("keypress keydown keyup blur", () => {
			var newSortcodeArray = sortcode.val().replaceAll(/\D/g,"").substring(0,6).match(/.{1,2}/g);
			var newSortcode = newSortcodeArray != null ? newSortcodeArray.join("-") : "";
			sortcode.val(newSortcode);
		});

		// Restrict certain fields to numbers only
		const restrictNumeric = function(bindId) {
			$(bindId).on("keypress keyup blur",function (event) {    
				$(this).val($(this).val().replaceAll(/\D/g, ""));
				if ((event.which < 48 || event.which > 57)) {
					event.preventDefault();
				}
			});
		}

		restrictNumeric(".js-bank-account-num-input");

		this.bankStatementUploader = new EcFileUploader({
			elementIdPrefix: "bank-statement",
			fileTypesRegex: this.docRegex
		});
		this.letterheadUploader = new EcFileUploader({
			elementIdPrefix: "letterhead",
			fileTypesRegex: this.docRegex
		});
		if (this.type != null) {
			$("#bank-type").attr("value", this.type.toUpperCase());
		}

		this.submitButton = $("#bank-details-submit");
		
		// Submit event handler
		$("#bank-details-form").on("submit", () => { this.postSubmitForm(false); return false; });

		// Event handler to submit whilst skipping verification documents
		$("#manual-document-upload").on("click", () => { this.postSubmitForm(true); return false; })

		// Cancel button binder
		$("#modal-cancel").off().on("click", this.config.hide);
	}

	// Submit new bank details from form in modal, optionally bypassing the document upload check
	postSubmitForm(bypassDocumentUpload) {
		const saveBtn = new LoadingButton(this.submitButton);
		saveBtn.disable();

		let data = $("#bank-details-form").serialize();
		
		if (bypassDocumentUpload) {
			data += "&bypass-document-upload=1"
		}
		
		$.ajax(this.config.postEndpoint, {
			method: "POST",
			data: data
		})
		.done(response => {
			if (this.errorHandler.handleErrors(response)) {
				// Form validation error
				$("#form-info").hide();
				$("#form-oops").hide();
				$("#form-oops").slideDown(this.fadeInMs);

				// Do we have a document warning?
				// if (this.errorHandler.getError("documentsMissing")) {
				// 	$("#error_documentsMissing").hide().slideDown(this.fadeInMs);
				// } else {
				// 	$("#error_documentsMissing").hide();
				// }

				saveBtn.enable();
			} 
			else {
				if (this.modal.length > 0) {
					// internal context - bank details are edited in a modal
					this.modal.on("hidden.bs.modal", function() {
						this.config.onComplete();
					}.bind(this));
					this.modal.modal("hide");

				} else {
					saveBtn.success();
					// charity admin context - go back to dashboard, which should now show a sucecss message.
					window.location.href = "/finance/bank-details";
				}
			}
		});

		return false;
	}
}