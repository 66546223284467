// Javascript for a form where password is entered and changed in the charity admin system.
// Takes care of admin account activation, forgot password and change password processes.
// @author Matthew Norris

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');

module.exports = function SetPassword() {
	this.init = function() {
		self.errorHandler = new AjaxFormErrorHandler();
		self.form = $("#form-password");
		self.form.on("submit", self.post);
		self.submit = $("#set-password-submit");
		self.submit.data("text", self.submit.val());
	}

	this.post = function() {
		self.submit.val("Processing...").addClass("disabled");
		$.ajax("/account/set-password", {
			method: "POST",
			data: self.form.serialize()
		}).done(self.receiveResponse);
		return false;
	}

	this.receiveResponse = function(response) {
		if (self.errorHandler.handleErrors(response)) {

			// there's something wrong with what the user submitted
			// list the errors at the top of the form, looks nicer than next to the fields.
			self.submit.val(self.submit.data("text")).removeClass("disabled");

			$("#oops").hide();
			$(".password_oops-message").hide();
			for (var i = 0; i < response.errors.length; i++) {
				var error = response.errors[i];
				$("#oops-messages_"+error["fieldName"]).text(error["message"]).show();
			}
			$("#oops").slideDown(self.fadeInMs);
		} else {
			// it worked! take user back to dashboard
			document.location = "/dashboard"
		}
	}
	
	var self = this;
	self.init();
};
