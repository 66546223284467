// General methods that are useful.
// @author Matthew Norris

var Utilities = function() {
	var self = this;

	// Generic method to determine the endpoint for contact AJAX requests. 
	// The URLs share a common structure which changes depending on internal / external admin context,
	// and also whether the entity is charity, organisation, contact or user
	this.getCrmEndpoint = function(endpointSuffix) {
		// Determine which kind of entity it is
		var organisationId = self.getCrmOrganisationId();
		var charityId = self.getCrmCharityId();
		var contactId = self.getCrmContactId();
		var userId = self.getCrmUserId();

		// Determine which type of CRM entity we are currently editing.
		var entityId;
		var entityName
		if (organisationId) {
			entityName = "organisation";
			entityId = organisationId;
		} else if (charityId) {
			entityName = "charity";
			entityId = charityId;
		} else if (contactId) {
			entityName = "contact";
			entityId = contactId;
		} else if (userId) {
			entityName = "user";
			entityId = userId;
		} else {
			throw new Exception("Failed to find a valid entity type")
		}

		// Build and return endpoint path
		if (self.isInternalAdmin()) { // Internal endpoint
			return "/internal/"+entityName+"/"+entityId+"/"+endpointSuffix;
		} else if (entityName == "charity") { // Charity admin endpoint
			return "/charity/"+endpointSuffix
		}
		return null;
	}

	// Whether we're in the internal section of the admin panel
	this.isInternalAdmin = function() {
		return window.location.pathname.includes("/internal");
	}

	// Extract CRM charity ID from the URL, or return null if none found. Not same as logged in charity!
	this.getCrmCharityId = function() {
		return self.getCrmId("charity");
	}

	// Extract CRM organisation ID from URL, or return null if none found
	this.getCrmOrganisationId = function() {
		return self.getCrmId("organisation");
	}

	// Extract CRM contact ID from URL, for dedicated contact page
	this.getCrmContactId = function() {
		return self.getCrmId("contact");
	}

	// Extract CRM contact ID from URL, for dedicated contact page
	this.getCrmUserId = function() {
		return self.getCrmId("user");
	}

	// Get an entity ID from the URL, or null if none found.
	this.getCrmId = function(key) {
		var urlPath = window.location.pathname;
		
		if (!urlPath.includes("/"+key+"/")) {
			console.log("Utilities.getCrmId", key, "FAIL");
			return null;
		}
		console.log("Utilities.getCrmId", key);
		return urlPath.split("/"+key+"/")[1].split("/").shift();
	}
};
	
module.exports = Utilities;
