/*
 * Simple wrapper for Select2
 * Main reason for this is to include the event handler
 */

const select2 = require('Vendor/select2/dist/js/select2.js');

module.exports = function Select2(el, options) {
	if(typeof el == "string") {
		el = $(el);
	}

	// Don't re-initiate
	el = el.filter((i,s) => {
		return !$(s).hasClass("select2-hidden-accessible")
	})

	if(!el.length) return;

	console.log("Select2", el, options || {});

	options = options || {};

	el.select2({
		...options, 
		width: '100%',
		theme: 'bootstrap'
	})
}