/*
	Javascript for a charity's bank details list/submit page
	@author Matthew Norris
*/

import BankDetailsEdit from 'Scripts/admin/bank-details-edit';

export default class BankDetails {
	constructor() {
		const bankDetailsEdit = new BankDetailsEdit({
			isCharity: true,
			onComplete: function() {
				window.location.href = "/finance/bank-details/";
			},
			postEndpoint: "/finance/bank-details-submit"
		});

		let type = window.location.href;
		type = type.substring(type.lastIndexOf("/") + 1);

		console.log("BankDetails()", type);

		bankDetailsEdit.type = type;
		bankDetailsEdit.setupForm();
	}
}