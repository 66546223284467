// Javascript for charity payout account creation
// @author Matthew Norris

'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const bsCollapse           = require('Scripts/common/bs-collapse');
const FormUtils            = require('Scripts/common/form-utils');
const LoadingButton        = require('Scripts/common/loading-button');
const TomSelect            = require('Scripts/common/tom-select').default;

module.exports = function StripeSetup() {

	// Initialise
	this.init = function() {
		self.formContainer = $("#form-container");
		self.url = "/finance/stripe-setup";
		self.errorHandler = new AjaxFormErrorHandler();
		self.load();
	}

	// Load the main bit of the page, this could show a form or a message saying that the
	// account creation is complete
	this.load = function() {
		self.bindUpdateButtons();

		var loading = $("#alert-please-wait");

		// Check for success or failure flags
		if (window.location.href.indexOf("success=1") > -1) {
			loading.hide(); // collapse doesn't work here. loading thing hangs around
			bsCollapse($("#alert-success")).show();

		} else if (window.location.href.indexOf("failure=1") > -1) {
			loading.hide(); // same as above here
			bsCollapse($("#alert-failure")).show();

		} else {
			$.ajax(self.url + "/form").done(function(response) {
				// Add HTML to container
				self.formContainer.html(response);
				
				// Bindings
				self.form = $("#account-details");
				if (self.form) {
					// Form exists so bind it
					self.submitButton = new LoadingButton($("#form-submit"));
					self.form.submit(self.submit); // Bind submit button
					$('input[type="url"]').on('blur', self.fixUrls); // Bind URL fixing
					self.fixUrls(); // Fix any existing URL data on the form
				}

				// Stripe update buttons, there will be a few of these in different alert boxes.
				self.bindUpdateButtons();

				// Load form bits like autocomplete on email address
				new FormUtils();

				// Setup country dropdown
				new TomSelect("#addressCountry");
				new TomSelect("#personData.addressCountry");
			});
		}
	}

	// Sort out URLs so they have http in front of them
	this.fixUrls = function() {
		$('input[type="url"]').each(function() {
			var string = $(this).val();
			if (!string.match(/^https?:/) && string.length) {
				string = "http://" + string;
				$(this).val(string)
			}
		});
	}

	// Bind all update buttons, can be called multiple times in one page load.
	this.bindUpdateButtons = function() {
		$(".update-button").off().on("click", self.sendToUpdate);
		$(".owner-update-button").off().on("click", self.verifyOwnerUpdate);
	}

	// Submit the form to its endpoint, this creates the Stripe account
	this.submit = function(e) {
		var alert = $("#alert-create-please-wait");

		$("html, body").animate({scrollTop: self.formContainer.parent().offset().top}, "slow");
		self.submitButton.disable(); // Needs to be before hiding otherwise width screws up
			
		// this bs event handler stuff is needed to avoid overlapping collapse() calls,
		// which cause issues
		self.formContainer.off().collapse("hide").on("hidden.bs.collapse", () => {
			alert.off().collapse("show").on("shown.bs.collapse", () => {
				$.ajax(self.url + "/create", {
					method: "POST",
					data: self.form.serialize(),
					dataType: "json"
				}).done(function(response) {

					if (response.stripeError) {
						// Stripe exception got thrown, this can happen in rare cases
						$("#alert-create-please-wait").hide();
						$("#js-stripe-problem").html(response.stripeError)
						$("#js-alert-stripe-problem").collapse("show");
						return false;
					}

					alert.collapse('hide');

					if (!response.validationResponse.success) {
						self.formContainer.collapse("show");
					}
					
					if (self.errorHandler.handleErrors(response.validationResponse)) {
						self.submitButton.enable();
					} 
					else {
						$("#alert-verify").collapse('show'); // contains link to Stripe to verify the account
						$("#verify-button").attr("href", response.stripeUrl);
					}
				});
			});
		});
		

		return false;
	}

	// Retrieve a Stripe link where the user can update their details, then redirect to it
	this.sendToUpdate = function(e) {
		var btn = new LoadingButton($(e.currentTarget));
		btn.disable();
		$.ajax(self.url + "/get-update-link").done(function(response) {
			window.location.href = !response ? self.url : response;
		});
	}

	// Show message to user asking them if they are sure they want to replace
	// the Stripe account owner. It's quite a simple thing really, but just looks
	// a bit nasty because of all the UI animation code
	this.verifyOwnerUpdate = function() {

		var accountExistsContainer = $("#js-account-exists-container");
		var replaceAccountContainer = $("#js-replace-account-container");
		var replaceAccountSpinner = $("#js-replace-account-spinner");

		// Hide message container, show dialog container
		accountExistsContainer.off().collapse("hide").on("hidden.bs.collapse", () => {
			replaceAccountContainer.off().collapse("show").on("shown.bs.collapse", () => {

				// Bind cancel button
				$(".cancel-owner-update-button").off().click(function() {
					replaceAccountContainer.off().collapse("hide").on("hidden.bs.collapse", () => {
						accountExistsContainer.off().collapse("show");
					});
				})

				// Bind action button to delete account, allowing new one to be made
				$(".confirm-owner-update-button").off().click(function() {
					replaceAccountContainer.off().collapse("hide").on("hidden.bs.collapse", () => {
						replaceAccountSpinner.collapse("show");
						// Deletes account from DB and refreshes the page so user can create a fresh account
						$.ajax(self.url + "/terminate-account").done(function(response) {
							location.reload();
						});
					});
				})

			});
		});
	}

	var self = this;
	self.init();
}