import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import fetchJson            from 'Scripts/common/fetch-json';
import Fetcher              from "Scripts/common/fetcher";
import Form                 from "Scripts/common/form";
import LoadingButton        from 'Scripts/common/loading-button';
import showErrorBar         from 'Scripts/common/show-error-bar';

export default class TicketDiscountCodeForm {
	constructor() {
		this.list = new Fetcher({
			callback: this.onLoadList.bind(this),
			element:  "#js-discount-codes-fetcher",
		});
	}

	onLoadList() {
		console.log("TicketDiscountCodeForm.onLoadList");

		$(".js-add-discount-code").on("click", this.onClickAddBtn.bind(this));
		$(".js-edit-discount-code").on("click", this.onClickEditBtn.bind(this));
		$(".js-remove-discount-code").on("click", this.onClickRemoveBtn.bind(this));
	}

	onClickAddBtn(e) {
		console.log("TicketDiscountCodeForm.onClickAddBtn");

		// Clear add modal, avoids issues with duplicate IDs
		this.editModal?.empty();

		this.addModal = new Fetcher({
			element: $("#js-add-discount-code-modal-fetcher"),
			modal: $("#js-modal-add-discount-code"),
			trigger: e.currentTarget,
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickEditBtn(e) {
		console.log("TicketDiscountCodeForm.onClickEditBtn");

		const btn = $(e.currentTarget);

		// Clear add modal, avoids issues with duplicate IDs
		this.addModal?.empty();

		this.editModal = new Fetcher({
			element: $("#js-edit-discount-code-modal-fetcher"),
			modal: $("#js-modal-edit-discount-code"),
			trigger: btn,
			data: btn.data(),
			callback: this.onLoadModal.bind(this),
		});
	}

	onClickRemoveBtn(e) {
		console.log("TicketDiscountCodeForm.onClickRemoveBtn");

		const endpoint = $(e.currentTarget).data('endpoint');
		const modal = $("#js-modal-remove-discount-code");
		const btn = modal.find(".js-submit");;

		modal.modal("show");

		btn.off().on("click", () => {
			this.onConfirmRemove(endpoint, btn, modal)
		});
	}

	async onConfirmRemove(endpoint, btn, modal) {
		console.log("TicketDiscountCodeForm.onConfirmRemove");

		btn = new LoadingButton(btn);
		btn.disable();

		const { success } = await fetchJson(endpoint, { method: 'POST' });

		if(!success) {
			showErrorBar();
		}

		btn.enable();
		modal.modal('hide');
		this.list.fetch();
	}

	onLoadModal(fetcher) {
		console.log("TicketDiscountCodeForm.onLoadModal");

		const modal = fetcher.options.modal;
		const form = modal.find("form");

		this.form = new Form({
			form: form,
			callback: () => {
				console.log("TicketDiscountCodeForm.onLoadModal.callback")
				modal.modal('hide');
				this.list.fetch();
			},
		});

		$(".js-toggle-discount-code-type").off().on('change', this.onChangeType.bind(this));
	}

	onChangeType(e) {
		console.log("TicketDiscountCodeForm.onChangeType");
		const isFixed = $(e.currentTarget).val() == 'FIXED';
		const modal = $(e.currentTarget).parents(".modal");
		const amountContainer = modal.find("#form-group--amount .input-group");
		const amountInput = amountContainer.find("input");
		const amountAddOn = amountContainer.find(".input-group-text");
		amountAddOn.text(isFixed ? '£' : '%');

		if(isFixed) {
			amountAddOn.insertBefore(amountInput);
		} else {
			amountAddOn.insertAfter(amountInput);
		}
	}
}