// JS for a form where charity admins who forgot their password can enter their 
// email address to get a password reset link.
// @author Matthew Norris

import Form from "Scripts/common/form";

export default class ResetPassword {
	constructor() {
		new Form({
			callback: this.afterPost.bind(this),
			form:     $("#js-reset-password-form"),
		});
	}

	afterPost() {
		$("#js-email-message").hide().slideDown() // Show "please check your email" message
	}
}
