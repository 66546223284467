// For modal where user can delete a contact. First we show a confirm screen with some info about the contact.
// The user can then post it off to confirm.
// @author Matthew Norris

const LoadingButton = require('Scripts/common/loading-button');
const Utilities     = require('Scripts/admin/utilities');

module.exports = function DeleteContact(parent) {
	
	this.init = function(parent) {
		self.parent = parent;
		self.utilities = new Utilities();
		self.modal = $("#contacts-delete-modal");
		self.contactId = null;
	};

	// Shows the modal, includes loading the form HTML using AJAX
	this.showModal = function(contactId) {
		console.log("DeleteContact.showModal()", contactId);

		self.contactId = contactId;
		var modalBody = self.modal.find(".modal-body");
		var modalSpinner = self.modal.find(".modal-body-spinner");

		modalBody.empty();
		modalSpinner.show();
		self.modal.modal('show');

		$.ajax(self.utilities.getCrmEndpoint("contacts/delete"), {
			method: "GET", 
			data: {"contactId": self.contactId}
		}).done(response => {
			modalSpinner.hide();
			modalBody.html(response);
			self.bindForm();
		});
		
		return false;	
	};

	this.bindForm = function() {
		self.btn = new LoadingButton($("#js-delete-confirm"));
		self.btn.el().on('click', self.post.bind(self));
	};

	this.post = function() {
		console.log("DeleteContact.post()");

		this.btn.disable();

		$.ajax(self.utilities.getCrmEndpoint("contacts/delete"), {
			method: "POST", 
			data: {"contactId": self.contactId}
		}).done(response => {
			var result = JSON.parse(response);
			if (result.logout) {
				document.location = "/"
			} 
			else {
				$("#contacts-delete-modal").modal("hide");
				self.parent.list.fetch();
			}
		});
	};

	var self = this;
	self.init(parent);
};
