// Change personal details form for charity admins.
// @author Matthew Norris

import Form from "Scripts/common/form";

export default class ChangePersonalDetails {
	constructor() {
		new Form({
			callback: this.afterPost.bind(this),
			form:     $("#js-personal-details-form"),
			willRedirect: true,
		});
	}

	afterPost() {
		window.location.href = "/account"; // redirect to the account overview page
	}
}
