
/*
 * This class allows the dragging and dropping of elements
 *
 * Requires 3 classes:
 * - js-dnd-parent - this goes on the element that contains all the draggable items
 * - js-dnd-item
 * - js-dnd-handle - an icon (fa-up-down) that indicates an element can be dragged
 */

export default class DragNDrop {
	constructor(opts) {
		this.opts = opts;

		this.items = $('.js-dnd-item');
		this.touched = false;

		this.items.each((i, item) => {
			this.bindEvents(item);
		});
	}

	bindEvents(item) {
		item.draggable    = true;
		item.ondragstart  = this.onDragStart.bind(this);
		item.ondragover   = this.onDragOver.bind(this);
		item.ontouchstart = this.onTouchStart.bind(this);
		item.ontouchmove  = this.onTouchMove.bind(this);
		item.ontouchend   = this.onTouchEnd.bind(this);
		
		item.ondrop   = this.onDrop.bind(this);
	}

	onDragStart(e) {
		console.log("DragNDrop.onDragStart()", e);

		// As we might have selected a child element, get the selected item
		this.selectedItem = this.getItem(e.target, e);

		if(!this.selectedItem) return;

		this.selectedItem.addClass('opacity-50');

		// .index() updates as we drag, so need to set the initial index
		this.initialIndex = this.selectedItem.index();
	}

	onDragOver(e) {
		e.preventDefault(); // Important!

		if(!this.selectedItem) return;
		
		this.underItem = this.getItem(e.target, e);
		this.moveItems();
	}

	onTouchStart(e) {
		console.log("DragNDrop.onTouchStart", e);

		const touchX  = e.touches[0].clientX;
		const screenX = window.screen.availWidth;

		// If using touch, allow right-most 30% to be used for scrolling, and also stop if tapping a link (link takes priority anyway)
		if($(e.target)[0].nodeName == 'A' || (touchX / screenX) > 0.7) {
			this.touched = false;
			return;
		}

		// We check for this in onTouchMove - if an invalid part is being moved, we stop the event and let native scroll take over
		this.touched = true;
		this.onDragStart(e);
	}

	onTouchMove(e) {
		if(e.cancelable) {
			e.preventDefault();
		}

		if(!this.touched) return;

		const touches = e.touches[0];
		const underItem = this.getItem($(document.elementFromPoint(touches.clientX, touches.clientY)), e);
		if(!underItem) return;

		this.underItem = underItem;
		this.moveItems();
	}

	onTouchEnd(e) {
		// Need to check that onTouchStart was valid, otherwise onDrop will throw an error
		if(!this.touched) return;

		this.onDrop(e);
	}

	// Need to ensure we're moving the row and not a child element (e.g. logo, text). If row can't be found, stop other drag events
	getItem(selectedElement, e) {
		selectedElement = $(selectedElement);
		const selectedItem = selectedElement.hasClass("js-dnd-item") ? selectedElement : selectedElement.parents(".js-dnd-item");
		
		if(!selectedItem.length) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}

		return selectedItem;
	}

	// Move the row - depending on if we're moving up or down, we use before() or after()
	moveItems() {
		const dragType = this.dragType();

		if(dragType) {
			this.underItem[dragType == 2 ? 'before' : 'after'](this.selectedItem)
		}
	}

	onDrop(e) {
		console.log("DragNDrop.onDrop()", 
			"Old index", (typeof this.initialIndex !== 'undefined' ? this.initialIndex : null), 
			"New index", (typeof this.underItem !== 'undefined' ? this.underItem.index() : null)
		);

		e.stopPropagation();

		this.touched = false;

		if(!this.selectedItem) {
			return;
		}

		this.selectedItem.removeClass('opacity-50');

		const newIndex = this.underItem.index();

		if(this.initialIndex !== newIndex) {
			if(this.opts.callback) {
				this.opts.callback(this.selectedItem);
			}
		}
	}

	// 0 = no movement 
	// 1 = move down 
	// 2 = move up
	dragType() {
		const before = this.selectedItem.index();
		const after  = this.underItem.index();

		return before == after ? 0 : before < after ? 1 : 2;
	}
}
