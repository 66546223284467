// Javascript for charity URL change page

import Form    from 'Scripts/common/form';
import Slugify from 'Vendor/slugify';

export default class CharityUrl {
	constructor() {
		this.form = new Form({
			form: $("#form-charity-url"),
			callback: this.submit.bind(this),
			willRedirect: true,
		});

		this.input = $("#url");
		this.input.on("blur", this.generateUri.bind(this));
	}

	generateUri() {
		this.input.val(Slugify(this.input.val(), {lower: true, remove: /[^A-Za-z0-9-_\s]/g})); // Spaces are "allowed" as Slugify auto-changes to dashes
	}

	submit() {
		location = window.location;
	}
}