// JS for the giftaid claim UI
// @author Matthew Norris

import Fetcher from "Scripts/common/fetcher";
import LoadingButton from "Scripts/common/loading-button"

export default class GiftAid {

	constructor() {
		this.confirmUndoModal = $("#js-confirm-undo-modal");
		this.url = "/finance/giftaid";
		this.fetch();
		
	}

	// Load the page contents using AJAX, then bind all the controls within the HTML
	fetch() {
		$.ajax({
			url: this.url + "/list",
			success: response => {
				$("#data-container").html(response);
				this.questionElement = $("#js-giftaid-question");
				this.helpElement = $("#js-giftaid-help");
				this.bindPromptControls();
			}
		});
	}

	bindPromptControls() {
		this.clickedYes();
		this.clickedNo();
		this.back();
		this.agree();
		this.claim();
		this.confirmUndo()
	}

	// When user clicks yes regarding a gift aid number, we store a flag, they are then 
	// asked the next question
	clickedYes() {
		const yesBtn = new LoadingButton($("#js-giftaid-yes"));
		const yesBtnEl = yesBtn.el();
		if (yesBtnEl.length === 0) return;
		yesBtnEl.on("click", () => {
			yesBtn.disable();
			$.ajax({
				url: this.url + "/set-giftaid-has-number",
				type: "POST",
				success: () => { this.fetch(); }
			});
		})
	}

	// When user indicates they do not have a gift aid number, show some help info.
	clickedNo() {
		const noButton = $("#js-giftaid-no");
		if (noButton.length === 0) return;
		noButton.click(() => {
			this.questionElement.hide();
			this.helpElement.show();
		});
	}

	// User can press a button to exit out of the help message
	back() {
		const backButton = $("#js-giftaid-help-back");
		if (backButton.length === 0) return;
		backButton.click(() => {
			this.questionElement.show();
			this.helpElement.hide();
			$("html, body").animate({scrollTop: this.questionElement.parents('.box').offset().top}, "slow");
		});
	}

	// When user agrees data usage constraints, store a flag and show the main screen.
	agree() {
		const agreeBtn = new LoadingButton($("#js-agree-btn"));
		const agreeBtnEl = agreeBtn.el();
		if (agreeBtnEl.length === 0) return;
		agreeBtnEl.on("click", () => {
			// Make sure tickbox is ticked. If not, show an error.
			if (!$("#js-agree-checkbox").is(":checked")) {
				// Not checked
				$("#js-agree-error").show();
			} else {
				// Checked
				agreeBtn.disable();
				$.ajax({
					url: this.url + "/agree",
					type: "POST",
					success: () => { this.fetch(); }
				});
			}
		});
	}

	// When user clicks the claim button, it will generate a report.
	claim() {
		const claimBtn = new LoadingButton($("#js-generate-btn"));
		const claimBtnEl = claimBtn.el();
		if (claimBtnEl.length === 0) return;
		claimBtnEl.on("click", () => {
			claimBtn.disable();
			$.ajax({
				url: this.url + "/create",
				type: "POST",
				success: () => { this.fetch(); }
			});
		});
	}

	// When user clicks undo, show "are you sure" confirm modal first
	confirmUndo() {
		$(".js-confirm-undo-btn").off().click(e => {
			const confirmButton = new LoadingButton($(e.currentTarget));
			confirmButton.disable();
			new Fetcher({
				callback: () => {
					$("#js-cancel-undo").click(e => { this.cancelUndo(e); });
					$("#js-undo").click(e => { this.undo(e); });
					confirmButton.enable();
				},
				data: $(e.currentTarget).data(), // Includes the ID (if editing)
				element: "#js-confirm-undo-modal-fetcher",
				modal: this.confirmUndoModal
			});
		});
	}

	cancelUndo(e) {
		// It'll already close the modal, just need to fetch the list
		this.fetch();
	}

	undo(e) {
		// Request undoing the report
		const undoButton = new LoadingButton($(e.currentTarget));
		undoButton.disable();
		$.ajax({
			url: this.url + "/undo",
			type: "POST",
			data: $(e.currentTarget).data(), // Includes the ID
			success: response => {
				// Close the modal
				this.confirmUndoModal.modal("hide");
				// Update the list
				this.fetch();
			}
		});
	}
}